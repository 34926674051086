import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Card } from '@mui/material';
import ClientSites from '../client-sites/ClientSites';
import CityLeagueStandings from '../city-league-standings/CityLeagueStandings';
import './LithiumCityLeagueTabs.css';
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';

ReactGA.initialize('G-1GBL3E4J72');

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className='simple-tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LithiumCityLeagueTabs = () => {
    const [value, setValue] = useState(0);
    const [standingData, setStandingData] = useState({ standingData: [], loading: false, error: null });
    const [leadingClientsData, setLeadingClientsData] = useState({ leading_clients_data: [], loading: false, error: null });
    const [color, setColor] = useState('purple');
   
    const handleChange = (event, newValue) => {
        setValue(newValue);
        const path = newValue === 0 ? '/city-lead-standing' : '/leading-client-sites';
        ReactGA.pageview(path);
    };
   
    const fetchCityLeagueStandings = useCallback(async () => {
        try {
            setStandingData(prev => ({ ...prev, loading: true }));
            const response = await axios.get('https://ritadevapi.project-lithium.com/noc-service/super-city-league-contest');
            if (response.data) {
                setStandingData({ standingData: response.data.standing_data, loading: false, error: null });
                setLeadingClientsData({ leading_clients_data: response.data.leading_clients_data, loading: false, error: null });
            }
        } catch (error) {
            console.error('Error fetching standings:', error);
            setStandingData({ standingData: [], loading: false, error });
        }
    }, []);

    useEffect(() => {
        fetchCityLeagueStandings();
        setColor('purple');
    }, [fetchCityLeagueStandings]);

    const handleSubmit = useCallback((value) => {
        setColor(value);
    }, []);

    // each tab onlick change this backgound color

    return (
        <div>
            <Card className='lithium-city-league-container'>
            <Box className='lithium-city-league-box-container' sx={{ width: '100%', background: value === 0 ? 'black' : value === 1 ? color : 'purple' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: "5px 20px" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            className='lithium-city-tab'
                            aria-label="basic tabs example"
                            centered
                        >
                            <Tab
                                label="City Lead Standing"
                                {...a11yProps(0)}
                                sx={{
                                    color: '#FFFFFF',
                                    '&.Mui-selected': {
                                        color: '#FFD700',
                                        fontWeight: "800",
                                    },
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                        fontWeight: "600"
                                    },
                                }}
                            />
                            <Tab
                                label="Leading Client Sites"
                                onClick={()=>setColor('slateblue')}
                                {...a11yProps(1)}
                                sx={{
                                    color: '#FFFFFF',
                                    '&.Mui-selected': {
                                        color: '#FFD700',
                                        fontWeight: "bold",
                                        padding: "0px"
                                    },
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                    },
                                }}
                            />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <CityLeagueStandings standingData={standingData} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <ClientSites leadingClientsData={leadingClientsData} onsubmit={handleSubmit} />
                    </CustomTabPanel>
                </Box>
            </Card>
        </div>
    );
}

export default LithiumCityLeagueTabs;
