import React, {useState} from 'react';
import './RandMContestStandings.css';
import cityLeague from '../../../assets/images/New_city_league.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import { toPng } from "html-to-image";
import DownloadIcon from '@mui/icons-material/Download';

const RandMContestStandings = (props) => {
  const standingData = props.standingData;

     const [imageDownloading, setImageDownloading] = useState(false);
  
     const handleDownloadImage = async () => {
      setImageDownloading(true); // Set loading state to true
    
      const tableElement = document.querySelector(".city-league-sites-container"); // Updated selector to include the entire container
    
      if (tableElement) {
        try {
          const dataUrl = await toPng(tableElement, {
            cacheBust: true,
            backgroundColor: "#ffffff", // Ensure a solid background color
            width: tableElement.offsetWidth, // Set width explicitly
            height: tableElement.offsetHeight, // Set height explicitly
          });
    
          // Create a link and download the image
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = `RandM_contest_${standingData?.rm_team_data[0]?.Week.toUpperCase()}.png`;
          link.click();
        } catch (error) {
          console.error("Failed to generate image:", error);
        } finally {
          setImageDownloading(false); // Reset loading state
        }
      } else {
        console.error("Table element not found for image download.");
        setImageDownloading(false);
      }
    };

  return (
    <div className='city-league-sites-container'>
            <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className='city-league-randm-grid-container'>
          <Grid item xs={2.5} sm={2}>
            <div>
              <img src={cityLeague} alt="league" className="city-league-image" />
            </div>
          </Grid>
          <Grid item xs={9.5} sm={9}>
          <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            <div className='city-league-sites-title'>R&M CONTEST</div>
            <div>
                {imageDownloading ? null: <button className='button-component' onClick={handleDownloadImage} disabled={imageDownloading}>
                  {imageDownloading ? "Downloading..." : <DownloadIcon style={{ fontSize: '20px', color: 'black' }} />}
                </button> }
              </div>
              </div>
            <div className='city-league-sites-sub-title'> {standingData?.rm_team_data && standingData?.rm_team_data[0]?.Week.toUpperCase()}</div>
          </Grid>
        </Grid>
      </Box>
      <div className='city-league-sites-table-container'>
        {standingData?.loading ? <CircularProgress /> : (
          <table className='city-league-sites-table'>
            <thead>
              <tr className='city-league-sites-table-row-title-rm'>
                <th></th>
                <th></th>
                <th>50%</th>
                <th>20%</th>
                <th>20%</th>
                <th>10%</th>
                <th>TOTAL</th>
              </tr>
              <tr className='city-league-sites-table-row'>
                <th>Rank</th>
                <th>Teams</th>
                <th>R&M Days</th>
                <th>TAT (VAM ID to Workshop)</th>
                <th>TAT (Workshop to closure)</th>
                <th>Repeat repair</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {standingData?.rm_team_data
                .sort((a, b) => a["Rank Today"] - b["Rank Today"]) // Sort by "Rank Today"
                .map((item, index) => (
                  <tr key={index}>
                    <td style={{ borderRight: "4px solid black" }}>{item["Rank Today"]}</td>
                    <td className='city-league-table-sl' style={{ borderRight: "4px solid black" }}>
                      {/* <span className="city-image-container">
                        <img src={CITY_IMAGES[item.City]} alt={item.City} className="city-image" />
                      </span> */}
                      <div className='city-league-table-column'>
                      {item.City}
                      </div>
                      {/* <span className="city-trend">
                      {item.Trend==="down" ? 
                      <img src={DownTriangle} className="city-trend-image" alt={item.City} />:
                      item.Trend==="up" ?
                      <img src={UpTriangle} className="city-trend-image" alt={item.City} />
                      : null}
                      </span> */}
                    </td>
                    <td className='data-cell'>{item['R&M Days']}</td>
                    <td className='data-cell-grey'>{item['TAT(VAM ID to Workshop']}</td>
                    <td className='data-cell'>{item["TAT(Workshop to closure)"]}</td>
                    <td className='data-cell-grey'>{item["Repeat Repair"]}</td>
                    <td className='data-total-cell'><div>{item["Total score "]}</div></td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default RandMContestStandings;
