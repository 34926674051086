import React from 'react';
import './SuperCityContestStandings.css';
// import Bangalore from '../../../assets/images/Bangalore.png';
// import Chennai from '../../../assets/images/Chennai.png';
// import NCR from '../../../assets/images/Delhi.png';
// import Pune from '../../../assets/images/Pune.png';
// import Mumbai from '../../../assets/images/Mumbai.png';
// import HYD from '../../../assets/images/Hyderabad.png';
import cityLeague from '../../../assets/images/New_city_league.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import backgroundImage from './Scoard_Bgm.png';
// import UpTriangle from '../../../assets/images/upTriangle.png'
// import DownTriangle from '../../../assets/images/downTriangle.png'

// const CITY_IMAGES = {
//   "Royal Challengers Bangalore ": Bangalore,
//   "Chennai Super Kings": Chennai,
//   "Delhi Daredevils": NCR,
//   "Salted Storm Pune": Pune,
//   "Mumbai Paltan": Mumbai,
//   "Hyderabadi Nawaabs": HYD,
// };

const SuperCityContestStandings = ({standingData}) => {

  const gridContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain', // Adjust to fit the container
    backgroundPosition: 'center', // Center the image
    flexWrap: 'wrap', // Allow items to wrap
    height: '80vh', // Adjust height as needed
    padding:"10px 0px"
  };

  return (
    <div style={gridContainerStyle}>
       <div style={{display:'inline', width:'100%'}}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-around', width:'100%'}}>
          <Grid item xs={1} sm={2}>
            <div>
              <img src={cityLeague} alt="league" className="city-league-image-contest" />
            </div>
          </Grid>
          <Grid item xs={7} sm={7}>
            <div className='city-league-sites-title-contest' style={{textAlign:'center'}}>SUPER CITY CONTEST LEAGUE 2.0</div>
          </Grid>
          <Grid item xs={1} sm={2}>
            <div>
              <img src={cityLeague} alt="league" className="city-league-image-contest" />
            </div>
          </Grid>
          </div>
        </Grid>
      </Box>
      <div className='city-league-target-container'>
        <div>
        Target Rupees <span className='city-league-target-container-sub-title'>&nbsp; <span className='amount-word'>18Cr</span> &nbsp;</span> {standingData?.run_rate_data && standingData.run_rate_data[0]?.Date} <span className='amount-word'>{standingData.run_rate_data[0]?.Achieved ? Number(standingData.run_rate_data[0]?.Achieved).toFixed(2) + "Cr": "" }</span>
        </div>
      </div>
      </div>
      {standingData.loading ? <CircularProgress /> : (
      <div className='city-league-sites-table-container'>
          <table border='1' className='city-league-sites-table-city city-league-sites-table-super-city'>
            <thead>
              <tr className='city-league-sites-table-row-city'>
                <th>Sr.No</th>
                <th>City</th>
                <th>Target For Month <br/><span className='small-words'>(In Rs Lacs)</span></th>
                <th>Plan MTD <br/><span  className='small-words'>(In Rs Lacs)</span></th>
                <th>Actual MTD <br/><span  className='small-words'>(In Rs Lacs)</span></th>
                <th>Current Run Rate <br/><span  className='small-words'>(Lacs per day)</span></th>
                <th>Required Run Rate <br/><span  className='small-words'>(Lacs per day)</span></th>
              </tr>
            </thead>
            <tbody>
              {standingData.run_rate_data
                .map((item, index) => (
                  <tr key={index}>
                    <td className='data-cell-city'>{index+1}</td>
                    <td className='data-cell-city'>
                        {item.City}
                    </td>
                    <td className='data-cell-city'>{item['Target for Month']}</td>
                    <td className='data-cell-city'>{item['Plan MTD']}</td>
                    <td className='data-cell-city'>{item['Actual MTD']}</td>
                    <td className='data-cell-city'>{item['Current Run Rate']}</td>
                    <td className='data-cell-city'>{item["Required Run Rate"]}</td>
                  </tr>
                ))}
            </tbody>
          </table>
      </div>
      )}
    </div>
  );
};

export default SuperCityContestStandings;
