import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './UberContest.css';
import cityLeague from '../../../assets/images/New_city_league.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import { toPng } from "html-to-image";
import DownloadIcon from '@mui/icons-material/Download';

const UberContest = ({ standingData }) => {
  const [selectedTeam, setSelectedTeam] = useState('BLR');

  // Memoized cities array to prevent unnecessary re-creation
  const cities = useMemo(() => ["BLR", "MUMBAI"], []);

  const handleTeamChange = useCallback((event) => {
    setSelectedTeam(event.target.value);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedTeam((prevCity) => {
        const currentIndex = cities.indexOf(prevCity);
        const nextIndex = (currentIndex + 1) % cities.length;
        return cities[nextIndex];
      });
    }, 15000); // 15 seconds interval

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [cities]);

  const filteredData = standingData.uber_team_data
    ? standingData.uber_team_data.filter((item) => item.City === selectedTeam)
    : [];

  const [imageDownloading, setImageDownloading] = useState(false);

  const handleDownloadImage = async () => {
    setImageDownloading(true); // Set loading state to true

    const tableElement = document.querySelector(".city-league-sites-container"); // Updated selector to include the entire container

    if (tableElement) {
      try {
        const dataUrl = await toPng(tableElement, {
          cacheBust: true,
          backgroundColor: "#ffffff", // Ensure a solid background color
          width: tableElement.offsetWidth, // Set width explicitly
          height: tableElement.offsetHeight, // Set height explicitly
        });

        // Create a link and download the image
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `uber_contest_${selectedTeam}_${new Date().toISOString()}.png`;
        link.click();
      } catch (error) {
        console.error("Failed to generate image:", error);
      } finally {
        setImageDownloading(false); // Reset loading state
      }
    } else {
      console.error("Table element not found for image download.");
      setImageDownloading(false);
    }
  };

  return (
    <div className='city-league-sites-container'>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className='city-league-uber-grid-container'>
          <Grid item xs={2.5} sm={2}>
            <div>
              <img src={cityLeague} alt="league" className="city-league-image" />
            </div>
          </Grid>
          <Grid item xs={9.5} sm={9}>
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
              <div className='city-league-sites-title'>UBER CONTEST</div>
              <div style={{ marginLeft: "10px" }}>
                <select
                  name="City"
                  id="City"
                  className="client-sites-container-select stylish-select-long"
                  onChange={handleTeamChange}
                  value={selectedTeam}
                >
                  {cities.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                {imageDownloading ? null : <button className='button-component' onClick={handleDownloadImage} disabled={imageDownloading}>
                  {imageDownloading ? "Downloading..." : <DownloadIcon style={{ fontSize: '20px', color: 'black' }} />}
                </button>}
              </div>
            </div>
            <div className='city-league-sites-sub-title'> {standingData.uber_team_data && standingData.uber_team_data[0]?.Week.toUpperCase()}</div>
          </Grid>
        </Grid>
      </Box>
      <div className='city-league-sites-table-container'>
        {standingData.loading ? <CircularProgress /> : (
          <table className='city-league-sites-table'>
            <thead>
              <tr className='city-league-sites-table-row-title'>
                <th></th>
                <th></th>
                <th>50%</th>
                <th>10%</th>
                <th>20%</th>
                <th>20%</th>
                <th>TOTAL</th>
              </tr>
              <tr className='city-league-sites-table-row'>
                <th>Rank</th>
                <th>Driver Manager</th>
                <th>Revenue</th>
                <th>Inventory Days</th>
                <th>Workshop</th>
                <th>Collection</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {filteredData
                .sort((a, b) => a.Rank - b.Rank) // Sort by "Rank Today"
                .map((item, index) => (
                  <tr key={index}>
                    <td style={{ borderRight: "4px solid black" }}>{item?.Rank}</td>
                    <td className='city-league-table' style={{ borderRight: "4px solid black" }}>
                      <div className='city-league-table-column'>
                        &nbsp;{item['Driver Manager']}
                      </div>
                    </td>
                    <td className='data-cell'>{item.Revenue}</td>
                    <td className='data-cell-grey'>{item.Inventory}</td>
                    <td className='data-cell'>{item["Workshop "]}</td>
                    <td className='data-cell-grey'>{item.Collection}</td>
                    <td className='data-total-cell'><div>{item["Total "]}</div></td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UberContest;
