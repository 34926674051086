import React, { useCallback, useEffect, useState } from 'react';
import './SpContestStandings.css';
import cityLeague from '../../../assets/images/New_city_league.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import UserIcon from '../../../assets/icons/User.png';
import axios from 'axios';
import { toPng } from "html-to-image";
import DownloadIcon from '@mui/icons-material/Download';

const SpContestStandings = ({ standingData }) => {
  const [selectedTeam, setSelectedTeam] = useState('bangalore');
  const [imageDownloading, setImageDownloading] = useState(false)
  const [spContestData, setSpContestData] = useState([])

  const [spContestCitiesData, setSpContestCitiesData] = useState([])
  const [spContestBusinessGroup, setSpContestBusinessGroup] = useState([])


  const handleDownloadImage = async () => {
    setImageDownloading(true); // Set loading state to true

    const tableElement = document.querySelector(".city-league-sites-container-sp");

    if (tableElement) {
      try {
        const dataUrl = await toPng(tableElement, {
          cacheBust: true,
          backgroundColor: "#ffffff", // Ensure a solid background color
          width: tableElement.offsetWidth, // Explicit width
          height: tableElement.offsetHeight, // Explicit height
          style: {
            transform: "scale(1)", // Prevent scaling issues
            transformOrigin: "top left", // Ensure proper alignment
          },
          useCORS: true, // Enable cross-origin handling for images
        });

        // Create a link and download the image
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `sp_contest_${selectedTeam}_${selectedGroup}_${selectedTimePeriod}_${spContestTimePeriod[0]?.time_period}.png`;
        link.click();
      } catch (error) {
        console.error("Failed to generate image:", error);
        alert("Failed to download the image. Please try again.");
      } finally {
        setImageDownloading(false); // Reset loading state
      }
    } else {
      console.error("Table element not found for image download.");
      setImageDownloading(false);
      alert("Error: Unable to locate the table for download.");
    }
  };



  const fetchCityAndBusingGroup = useCallback(async () => {
    const cities = "cities"
    const groups = "business_groups"
    try {
      const response = await axios.get(`https://ritaprodapi.project-lithium.com/noc-service/sp_competition/labels_cities_business_groups?type=${cities}`);

      if (response?.data?.data) {
        setSpContestCitiesData(response.data.data)
      }

      const businessGroup = await axios.get(`https://ritaprodapi.project-lithium.com/noc-service/sp_competition/labels_cities_business_groups?type=${groups}`);

      if (businessGroup?.data?.data) {
        setSpContestBusinessGroup(businessGroup.data.data)
      }

    } catch (error) {
      console.error('Error fetching standings:', error);
    }
  }, []);

  useEffect(() => {
    fetchCityAndBusingGroup()
  }, [fetchCityAndBusingGroup])

  const [selectedTimePeriod, setSelectedTimePeriod] = useState('week');
  const [spContestTimePeriod, setSpContestTimePeriod] = useState([])

  const fetchSpContestPeriodData = useCallback(async () => {
    try {
      const response = await axios.get(`https://ritaprodapi.project-lithium.com/noc-service/sp_competition/labels?type=${selectedTimePeriod}`);

      if (response?.data?.data) {
        setSpContestTimePeriod(response.data.data)
        setSelectedSpContestTime(response.data.data[response.data.data.length-1].label)
      }

    } catch (error) {
      console.error('Error fetching standings:', error);
    }
  }, [selectedTimePeriod]);

  useEffect(() => {
    fetchSpContestPeriodData()
  }, [fetchSpContestPeriodData])

  const handleTeamChange = useCallback((event) => {
    setSelectedTeam(event.target.value);
  }, []);

  const [selectedGroup, setSelectedGroup] = useState('D12');


  const handleGroupChange = useCallback((event) => {
    setSelectedGroup(event.target.value);
  }, []);

  const handleSelectedPeriodChange = useCallback((event) => {
    setSelectedTimePeriod(event.target.value);
  }, []);

  const [selectedSpContestTime, setSelectedSpContestTime] = useState("")

  const handleSelectedSpContestTimeChange = useCallback((event) => {
    setSelectedSpContestTime(event.target.value);
  }, []);

  // Filter data based on selected team
  const filteredData = spContestData
    ? spContestData.filter((item) => item.city === selectedTeam.toLowerCase() && item.business_group === selectedGroup)
    : [];

  const fetchCityLeagueStandings = useCallback(async () => {
    try {
      const payload = {
        label: selectedSpContestTime,
        city: selectedTeam,
        business_group: selectedGroup,
      };

      const response = await axios.post(
        'https://ritaprodapi.project-lithium.com/noc-service/sp_competition/labels_cities_business_groups_data',
        payload
      );

      if (response?.data?.data) {
        setSpContestData(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching standings:', error);
    }
  }, [selectedSpContestTime, selectedGroup, selectedTeam]);


  useEffect(() => {
    fetchCityLeagueStandings()
  }, [fetchCityLeagueStandings])

  useEffect(() => {
    if (spContestCitiesData.length > 0) {
      let currentIndex = spContestCitiesData.indexOf(selectedTeam);
  
      const interval = setInterval(() => {
        // Update index to the next city, loop back to the start if at the end
        currentIndex = (currentIndex + 1) % spContestCitiesData.length;
        setSelectedTeam(spContestCitiesData[currentIndex]);
      }, 15000); // 15 seconds
  
      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [spContestCitiesData, selectedTeam]);
  

  return (
    <div className='city-league-sites-container-sp'>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className='sp-league-sites-grid-container'>
          <Grid item xs={2.5} sm={2}>
            <div>
              <img src={cityLeague} alt="league" className="city-league-image" />
            </div>
          </Grid>
          <Grid item xs={11} sm={9}>
            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
              <div className='city-league-sites-title-sp'>SP CONTEST</div>
              <div style={{ marginLeft: "10px" }}>
                <select
                  name="Team"
                  id="Team"
                  className='client-sites-container-select stylish-select-long'
                  onChange={handleTeamChange}
                  value={selectedTeam}
                >
                  {spContestCitiesData.map((item) => (
                    <option value={item}>{item === "bangalore" ? "Bangalore (BLR, HSR, MPL, BGM, CBE)" : item === "ncr" ? "NCR-NOIDA, NCR-GGN, IDR, KOL" : item === "mum_pnq_chn" ? "MUM-PNQ-CHN" : item === "hyderabad" ? "Hyderabad (HYD)" : null}</option>
                  ))}
                </select>
              </div>

              <div>
                <select
                  name="Team"
                  id="Team"
                  className='client-sites-container-select stylish-select'
                  onChange={handleGroupChange}
                  value={selectedGroup}
                >
                  {spContestBusinessGroup.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  name="Period"
                  id="time_period"
                  className='client-sites-container-select stylish-select'
                  onChange={handleSelectedPeriodChange}
                  value={selectedTimePeriod}
                >
                  <option value="week">Weekly</option>
                  <option value="month">Monthly</option>
                  <option value="quarter">Quarterly</option>
                </select>
              </div>
              <div>
                <select
                  name="time"
                  id="time"
                  className='client-sites-container-select stylish-select-long'
                  onChange={handleSelectedSpContestTimeChange}
                  value={selectedSpContestTime}
                >
                  {spContestTimePeriod.map((item) => (
                    <option value={item.label}>{item.time_period}</option>
                  ))}
                </select>
              </div>
              <div>
                {imageDownloading ? null : <button className='button-component' onClick={handleDownloadImage} disabled={imageDownloading}>
                  {imageDownloading ? "Downloading..." : <DownloadIcon style={{ fontSize: '20px', color: 'black' }} />}
                </button>}
              </div>

            </div>

            <div className='city-league-sites-sub-title'>
            {filteredData && filteredData[0]?.as_on_date.toUpperCase()}
            </div>
          </Grid>
        </Grid>
      </Box>
      <div className='city-league-sites-table-container'>
        {standingData.loading ? (
          <CircularProgress />
        ) : (
          <table className='city-league-sites-table'>
            <thead>
              {/* <tr className='city-league-sites-table-row-title'>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>40%</th>
                <th>20%</th>
                <th>20%</th>
                <th>20%</th>
                <th>TOTAL</th>
              </tr> */}
              <tr className='city-league-sites-table-row'>
                <th>RANK</th>
                <th>SP Lithium Id</th>
                <th>Site</th>
                <th>Group</th>
                <th>SP Attendance</th>
                <th>OTA / OTD</th>
                <th>Trips</th>
                <th>Referral</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.sort((a, b) => a.rank_today - b.rank_today).map((item, index) =>
              (<tr key={index}>
                <td style={{ borderRight: '4px solid black' }}>{item['rank_today']}</td>
                <td className='city-league-table' style={{ borderRight: '4px solid black' }}>
                  <div className='city-league-table-column'>
                    {imageDownloading ? null :
                    <span className="city-image-container">
                      <img
                        src={item?.sp_image || UserIcon}
                        alt="img"
                        className="city-image"
                        onError={(e) => {
                          e.target.src = UserIcon; // Fallback to UserIcon if the image fails to load 
                        }}
                      />
                    </span>}
                    {item.sp_id_name}
                  </div>
                </td>
                <td className='data-cell'>{item.sp_site}</td>
                <td className='data-cell-grey'>{item.business_group}</td>
                <td className='data-cell'>{item.attendance}</td>
                <td className='data-cell-grey'>{item.ota_otd_100_percent_points}</td>
                <td className='data-cell'>{item.trips}</td>
                <td className='data-cell-grey'>{item.referral}</td>
                <td className='data-total-cell'><div>{item.total_score}</div></td>
              </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SpContestStandings;
