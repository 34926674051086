import React, {useState} from 'react';
import './SpHiringContestStandings.css';
import cityLeague from '../../../assets/images/New_city_league.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import { toPng } from "html-to-image";
import DownloadIcon from '@mui/icons-material/Download';

const SpHiringContestStandings = ({standingData}) => {

   const [imageDownloading, setImageDownloading] = useState(false);
    
  const handleDownloadImage = async () => {
    setImageDownloading(true); // Set loading state to true

    const tableElement = document.querySelector(".city-league-sites-container"); // Updated selector to include the entire container

    if (tableElement) {
      try {
        const dataUrl = await toPng(tableElement, {
          cacheBust: true,
          backgroundColor: "#ffffff", // Ensure a solid background color
          width: tableElement.offsetWidth, // Set width explicitly
          height: tableElement.offsetHeight, // Set height explicitly
        });

        // Create a link and download the image
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `spHiring_contest_${standingData.sp_hiring_data[0]?.Week.toUpperCase()}.png`;
        link.click();
      } catch (error) {
        console.error("Failed to generate image:", error);
      } finally {
        setImageDownloading(false); // Reset loading state
      }
    } else {
      console.error("Table element not found for image download.");
      setImageDownloading(false);
    }
  };

  return (
    <div className='city-league-sites-container'>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className='city-league-sp-hiring-grid-container'>
          <Grid item xs={2.5} sm={2}>
            <div>
              <img src={cityLeague} alt="league" className="city-league-image" />
            </div>
          </Grid>
          <Grid item xs={9.5} sm={9}>
          <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            <div className='city-league-sites-title'>SP HIRING CONTEST</div>
            <div>
                {imageDownloading ? null: <button className='button-component' onClick={handleDownloadImage} disabled={imageDownloading}>
                  {imageDownloading ? "Downloading..." : <DownloadIcon style={{ fontSize: '20px', color: 'black' }} />}
                </button> }
              </div>
            </div>
            <div className='city-league-sites-sub-title'> {standingData.sp_hiring_data && standingData.sp_hiring_data[0]?.Week.toUpperCase()}</div>
          </Grid>
        </Grid>
      </Box>
      <div className='city-league-sites-table-container'>
      {standingData.loading  ? <CircularProgress /> : (
          <table className='city-league-sites-table'>
            <thead>
              <tr className='city-league-sites-table-row-title'>
                <th></th>
                <th></th>
                <th>40%</th>
                <th>40%</th>
                <th>10%</th>
                <th>10%</th>
                <th>TOTAL</th>
              </tr>
              <tr className='city-league-sites-table-row'>
                <th>Rank</th>
                <th>Teams</th>
                <th>Active SP's to <br/> Normative SP's</th>
                <th>SP Attendance <br/> (Less than 7 days)</th>
                <th>Temp ID to Per ID <br/>
                 (48 Hrs)
                </th>
                <th>Temp ID to Per ID <br/>
                 (From Previous Leads)
                </th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
            {standingData.sp_hiring_data
                .sort((a, b) => a["Rank"] - b["Rank"])// Sort by "Rank Today"
                .map((item, index) => (
                  <tr key={index}>
                    <td style={{ borderRight: "4px solid black" }}>{item["Rank"]}</td>
                    <td className='city-league-table' style={{ borderRight: "4px solid black" }}>
                       <div className='city-league-table-column'>
                       {item.Team}
                       </div>
                    </td>
                    <td className='data-cell'>{item["Active SP's \n(Less than 7 Days)"]}</td>
                    <td className='data-cell-grey'>{item["SP Attendance\n(Less than 7 Days)"]}</td>
                    <td className='data-cell'>{item["Temp ID to Per ID\n(48 Hrs)"]}</td>
                    <td className='data-cell-grey'>{item["Temp ID to Per ID\n(From Previous Leads)"]}</td>
                    <td className='data-total-cell'><div>{item["Total score "]}</div></td> 
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SpHiringContestStandings;
