import React, {useState} from 'react';
import './CityLeagueStandings.css';
import Bangalore from '../../../assets/images/Bangalore.png';
import Chennai from '../../../assets/images/Chennai.png';
import NCR from '../../../assets/images/Delhi.png';
import Pune from '../../../assets/images/Pune.png';
import Mumbai from '../../../assets/images/Mumbai.png';
import HYD from '../../../assets/images/Hyderabad.png';
import cityLeague from '../../../assets/images/New_city_league.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import { toPng } from "html-to-image";
import DownloadIcon from '@mui/icons-material/Download';

const CITY_IMAGES = {
  "Royal Challengers Bangalore ": Bangalore,
  "Chennai Super Kings": Chennai,
  "Delhi Daredevils": NCR,
  "Salted Storm Pune": Pune,
  "PUN-MUM-CHN Toofani Kings": Mumbai,
  "Hyderabadi Nawaabs": HYD,
};

const CityLeagueStandings = ({standingData}) => {

   const [imageDownloading, setImageDownloading] = useState(false);

   const handleDownloadImage = async () => {
    setImageDownloading(true); // Set loading state to true
  
    const tableElement = document.querySelector(".city-league-sites-container"); // Updated selector to include the entire container
  
    if (tableElement) {
      try {
        const dataUrl = await toPng(tableElement, {
          cacheBust: true,
          backgroundColor: "#ffffff", // Ensure a solid background color
          width: tableElement.offsetWidth, // Set width explicitly
          height: tableElement.offsetHeight, // Set height explicitly
        });
  
        // Create a link and download the image
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `city_contest_${standingData.standingData[0]?.Week.toUpperCase()}.png`;
        link.click();
      } catch (error) {
        console.error("Failed to generate image:", error);
      } finally {
        setImageDownloading(false); // Reset loading state
      }
    } else {
      console.error("Table element not found for image download.");
      setImageDownloading(false);
    }
  };
  

  return (
    <div className='city-league-sites-container safety-table'>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className='city-league-sites-grid-container'>
          <Grid item xs={2.5} sm={2}>
            <div>
              <img src={cityLeague} alt="league" className="city-league-image" />
            </div>
          </Grid>
          <Grid item xs={9.5} sm={9}>
          <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            <div className='city-league-sites-title'>CITY CONTEST</div>
            <div>
                {imageDownloading ? null: <button className='button-component' onClick={handleDownloadImage} disabled={imageDownloading}>
                  {imageDownloading ? "Downloading..." : <DownloadIcon style={{ fontSize: '20px', color: 'black' }} />}
                </button> }
              </div>
            </div>
            <div className='city-league-sites-sub-title'> {standingData.standingData && standingData.standingData[0]?.Week.toUpperCase()}</div>
          </Grid>
        </Grid>
      </Box>
      <div className='city-league-sites-table-container' style={{background:'black'}}>
        {standingData.loading ? <CircularProgress /> : (
          <table className='city-league-sites-table'>
            <thead>
              <tr className='city-league-sites-table-row-title-city'>
                <th></th>
                <th></th>
                <th>50%</th>
                <th>25%</th>
                <th>15%</th>
                <th>10%</th>
                <th>TOTAL</th>
              </tr>
              <tr className='city-league-sites-table-row'>
                <th>Rank</th>
                <th>Teams</th>
                <th>Revenue</th>
                <th>Workshop</th>
                <th>Margin</th>
                <th>OTA / OTD</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {standingData.standingData
                .sort((a, b) => a["Rank Today"] - b["Rank Today"]) // Sort by "Rank Today"
                .map((item, index) => (
                  <tr key={index}>
                    <td style={{ borderRight: "4px solid black" }}>{item["Rank Today"]}</td>
                    <td className='city-league-table-td' style={{ borderRight: "4px solid black" }}>
                    <div className='city-league-table-column'>
                      <span className="city-image-container">
                        <img src={CITY_IMAGES[item.City]} alt={item.City} className="city-image" />
                      </span>
                      {item.City}
                      </div>
                    </td>
                    <td className='data-cell'>{item.Revenue}</td>
                    <td className='data-cell-grey'>{item["Vehicle Days in Workshop"]}</td>
                    <td className='data-cell'>{item["City Level Margin"]}</td>
                    <td className='data-cell-grey'>{item["OTA/OTD"]}</td>
                    <td className='data-total-cell'><div>{item["Total score "]}</div></td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CityLeagueStandings;
