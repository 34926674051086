import React, { useCallback, useEffect, useState } from 'react';
import './SiteTeamContestStandings.css';
import cityLeague from '../../../assets/images/New_city_league.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import { toPng } from "html-to-image";
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios'

const teams = ["Infantry", "Marines", "Commandos", "SEAL"]; // Array of team options

const SiteTeamContestStandings = ({ standingData }) => {
  const [selectedTeam, setSelectedTeam] = useState('Infantry');
  const [imageDownloading, setImageDownloading] = useState(false);

  const handleTeamChange = useCallback((event) => {
    setSelectedTeam(event.target.value);
  }, []);

  // Automatically change team every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedTeam((prevTeam) => {
        const currentIndex = teams.indexOf(prevTeam);
        const nextIndex = (currentIndex + 1) % teams.length;
        return teams[nextIndex];
      });
    }, 15000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const handleDownloadImage = async () => {
    setImageDownloading(true);

    const tableElement = document.querySelector(".safety-table");

    if (tableElement) {
      try {
        const dataUrl = await toPng(tableElement, {
          cacheBust: true,
          backgroundColor: "#ffffff",
        });

        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `site_contest_${selectedTeam}_${standingData.site_team_data[0]?.Week.toUpperCase()}.png`;
        link.click();
      } catch (error) {
        console.error("Failed to generate image:", error);
      } finally {
        setImageDownloading(false);
      }
    } else {
      console.error("Table element not found for image download.");
      setImageDownloading(false);
    }
  };

  const [selectedSpContestTime, setSelectedSpContestTime] = useState("")
  const [spContestTimePeriod, setSpContestTimePeriod] = useState([])
  
  const handleSelectedSpContestTimeChange = useCallback((event) => {
    setSelectedSpContestTime(event.target.value);
  }, []);

  const filteredData = standingData.site_team_data
  ? standingData.site_team_data.filter((item) => item.Team === selectedTeam && item["Week Range"] === selectedSpContestTime)
  : [];

  const fetchSpContestPeriodData = useCallback(async () => {
    try {
      const response = await axios.get(`https://ritaprodapi.project-lithium.com/noc-service/sp_competition/labels?type=week`);

      if (response?.data?.data) {
        const res = response?.data?.data.map((item)=>
          ({
            "label":item.label,
            "time_period": item.time_period.replace("\u2013", "-")
          }))  
        setSpContestTimePeriod(res)
        setSelectedSpContestTime(response.data.data[response.data.data.length-1].time_period.replace("\u2013", "-"))
      }

    } catch (error) {
      console.error('Error fetching standings:', error);
    }
  }, []);

  useEffect(() => {
    fetchSpContestPeriodData()
  }, [fetchSpContestPeriodData])


  return (
    <div className='city-league-sites-container safety-table'>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} className='city-league-sites-team-grid-container'>
          <Grid item xs={2.5} sm={2}>
            <div>
              <img src={cityLeague} alt="league" className="city-league-image" />
            </div>
          </Grid>
          <Grid item xs={9.5} sm={9}>
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
              <div className='city-league-sites-title'>SITE CONTEST</div>
              <div style={{ marginLeft: "10px" }}>
                <select
                  name="Team"
                  id="Team"
                  className="client-sites-container-select stylish-select-long"
                  onChange={handleTeamChange}
                  value={selectedTeam}
                >
                  {teams.map((team) => (
                    <option key={team} value={team}>{team}</option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  name="time"
                  id="time"
                  className='client-sites-container-select stylish-select-long'
                  onChange={handleSelectedSpContestTimeChange}
                  value={selectedSpContestTime}
                >
                  {spContestTimePeriod.map((item) => (
                    <option value={item.time_period}>{item.time_period}</option>
                  ))}
                </select>
              </div>
              <div>
                {imageDownloading ? null : (
                  <button
                    className='button-component'
                    onClick={handleDownloadImage}
                    disabled={imageDownloading}
                  >
                    {imageDownloading ? "Downloading..." : <DownloadIcon style={{ fontSize: '20px', color: 'black' }} />}
                  </button>
                )}
              </div>
            </div>
            <div className='city-league-sites-sub-title'>
              {standingData.site_team_data && standingData.site_team_data[0]?.Week.toUpperCase()}
            </div>
          </Grid>
        </Grid>
      </Box>
      <div className='city-league-sites-table-container'>
        {standingData.loading ? <CircularProgress /> : (
          <table className='city-league-sites-table'>
            <thead>
              <tr className='city-league-sites-table-row-title'>
                <th></th>
                <th></th>
                <th></th>
                <th>{selectedTeam === "Marines" || selectedTeam === "Commandos" || selectedTeam === "SEAL" ? "50%" : "40%"}</th>
                <th>20%</th>
                <th>{selectedTeam === "Commandos" ? "15%" : "20%"}</th>
                <th>{selectedTeam === "Commandos" ? "15%" : selectedTeam === "Marines" || selectedTeam === "SEAL" ? "10%" : "20%"}</th>
                <th>TOTAL</th>
              </tr>
              <tr className='city-league-sites-table-row'>
                <th>Rank</th>
                <th>Site</th>
                <th>City</th>
                <th>Revenue</th>
                <th>{selectedTeam === "Commandos" ? "SP Productivity" : "SP Attendance"}</th>
                <th>R & M</th>
                <th>OTA / OTD</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {filteredData
                .sort((a, b) => a["Rank Today"] - b["Rank Today"])
                .map((item, index) => (
                  <tr key={index}>
                    <td style={{ borderRight: "4px solid black" }}>{item["Rank Today"]}</td>
                    <td className='city-league-table' style={{ borderRight: "4px solid black" }}>
                      <div className='city-league-table-column'>
                        {item["Site Group"]}
                      </div>
                    </td>
                    <td className='data-cell-grey'>{item.City}</td>
                    <td className='data-cell'>{item.Revenue}</td>
                    <td className='data-cell-grey'>{selectedTeam === "Commandos" ? item['SP Productivity'] : item['SP Attendance']}</td>
                    <td className='data-cell'>{item["R&M"]}</td>
                    <td className='data-cell-grey'>{item["OTA/OTD"]}</td>
                    <td className='data-total-cell'><div>{item["Total score"]}</div></td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SiteTeamContestStandings;
