import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screens/Home/Home';
import AccessDenied from './components/AccessDenied/AccessDenied'; // Import AccessDenied component
import LithiumCityLeagueTabs from './screens/lithium-city-super-league/lithium-city-league-tabs/LithiumCityLeagueTabs';
import LithiumCityLeagueSeason2 from './screens/lithium-city-super-league-2/lithium-city-league-tabs/LithiumCityLeagueTabs';
import LithiumCityLeagueSeasonFull from './screens/lithium-city-super-league-2-full/lithium-city-league-tabs/LithiumCityLeagueTabs';
import SafetySuggestions from './screens/safety-suggestions/SafetySuggestions';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:regNumber" element={<Home />} />
        <Route path="/safety-suggestion" element={<SafetySuggestions />} />
        <Route path="/contest/lithium-super-city-league" element={<LithiumCityLeagueTabs />} />
        <Route path="/contest/lithium-super-city-league-2" element={<LithiumCityLeagueSeason2 />} />
        <Route path="/contest/fullscreen/lithium-super-city-league-2" element={<LithiumCityLeagueSeasonFull />} />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="*" element={<AccessDenied />} /> {/* Fallback for unmatched routes */}
      </Routes>
    </Router>
  );
}

export default App;
